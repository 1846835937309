.largeHero {
	border-top-left-radius: 15% 20%;
	border-bottom-right-radius: 25% 33%;
	box-shadow: 0 2px 4px 0 rgba(0 0 0 / 15%);
}

.smallHero {
	bottom: -30px;
	right: -35px;
	border-top-left-radius: 30% 40%;
	border-bottom-right-radius: 30% 40%;
	height: 60%;
	box-shadow: 0 12px 32px 0 rgba(0 0 0 / 30%);
}

@media (--breakpoint-medium) {
	.smallHero {
		bottom: -30px;
		right: -30px;
		height: auto;
	}
}

@media (--breakpoint-large) {
	.smallHero {
		bottom: -50px;
		right: -30px;
		height: 60%;
	}
}
